<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"/>

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/UpdateStaff"
      finish-router="staff"
      permission="Sta_Edit"
      :submit-action="submitAction"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs
          :company-id="scope.rowData.companyID"
          :row-data="scope.rowData"
          @onRoleListChange="onRoleListChange"/>
      </template>

      <template v-slot:loginNfcs="scope">
        <field-inputs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="loginNfcs"
          :show-add-btn="false"
          :show-delete-btn="false"/>
      </template>

      <template v-slot:callBellNfcs="scope">
        <field-modal-call-bell-nfcs
          :is-edit="scope.isEdit"
          :row-data="scope.rowData"
          :root-item="scope.rootItem"
          :items="row_data.callBellNfcs"
          :facilityAndRoleList="facilityAndRoleList"
          :show-add-btn="true"
          :show-delete-btn="true"
          @updateValues="onCallBellNfcsUpdate"/>
      </template>

    </edit-field>
  </page-content>
</template>

<script>

import fields, { reloadFieldData } from '@/views/staff/fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacilityRoleIDs from '@/views/staff/SlotFacilityRoleIDs'
import SlotFloor from '@/views/bed/SlotFloor'
import FieldInputs from '../../components/FieldInputs'
import FieldModalCallBellNfcs from './FieldModalCallBellNfcs.vue'
import _ from 'lodash'

export default {
  name: 'Edit',
  components: {
    SlotFloor,
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    FieldInputs,
    FieldModalCallBellNfcs,
  },
  data() {
    return {
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      facilityAndRoleList: [],
      row_data: {},
      orig_data: {},
    }
  },
  computed: {
    loginNfcs() {
      if (this.row_data.loginNfcs?.length >= 1) return this.row_data.loginNfcs
      return []
    },
    callBellNfcs() {
      if (this.row_data.facilityNfcs?.length >= 1) {
        return this.row_data.facilityNfcs.map(obj => {
          return obj.facilityName ? `${obj.nfcTag} (${obj.facilityName})` : obj.nfcTag
        })
      }
      return []
    },
  },
  created() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }

    reloadFieldData()
    common.apiGetData('/staffmanage/GetStaff?staffID=' + this.id)
      .then(res => {
        const orig_data = {
          ...res.data.staff,
          callBellNfcs: res.data.callBellNfcs, // merge "callBellNfcs"
        }
        this.row_data = _.cloneDeep(orig_data)
        this.orig_data = _.cloneDeep(orig_data)
        // this.fields.filter(f => {
        //   if (['callBellNfcTitle', 'callBellNfcs'].includes(f.name)) {
        //     f.edit_show = res.data.isShowCallBell ?? false
        //   }
        // })
      })
  },
  methods: {
    onRoleListChange(facilityAndRoleList) {
      this.facilityAndRoleList = facilityAndRoleList
    },
    onCallBellNfcsUpdate(nfcs) {
      this.row_data.callBellNfcs = nfcs
    },
    submitAction(rowData, api, finishRouter) {
      const that = this

      // 檢查特定的編輯字段
      const fieldsToConfirm = []
      if (!_.isEqual(this.orig_data.callBellNfcs, this.row_data.callBellNfcs)) {
        fieldsToConfirm.push(common.getI18n('staff.call_bell_nfc'))
      }

      // 不包含特定字段，直接提交
      if (fieldsToConfirm.length === 0) return submit()

      // 包含特定字段，提示確認
      common.showConfirm({
        title: common.getI18n('common.form_field_edit_confirm_tip')
          .replace('{_field_}', `"${fieldsToConfirm.join('" "')}"`),
        confirm_fun: () => submit()
      })

      function submit() {
        that.$requestWehealth({ method: 'post', url: api, data: rowData })
          .then(() => that.$router.push({ name: finishRouter }))
          .catch((err) => {
            const res = err.cause?.respond
            if (
              res?.result_msg === "CallBellNfcIsUsing"
              && res?.data?.staff
              && res?.data?.nfcSerialNumber
            ) {
              common.showAlert({
                title: common.getI18n('error.CallBellNfcIsUsing'),
                text: `NFC: ${res?.data?.nfcSerialNumber}`
                  + ` | ${common.getI18n('common.staff')}: ${res?.data?.staff.chineseName}`,
              })
            }
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
